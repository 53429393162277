import AppRouter from './route/app.router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import httpService from './services/http.service';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    httpService.httpInterceptors();
  }, []);
  return <AppRouter />;
}

export default App;
