import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const PublicRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate('/client-information');
    }
  }, []);
  return children;
};
