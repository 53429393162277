import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function HeaderComponents() {
  const page_heading = useSelector((state) => state.pageHeadingReducer.page_heading);
  return (
    <header className="p-20 daz-header">
      <div className="daz-header__logo ">
        <Link to="/client-information">
          <img src="../../../dazoq-logo-black.png" alt="Dazok Logo"></img>
        </Link>
      </div>
      <h4 className="align-self-center">{page_heading}</h4>

      <div>
        <Dropdown className="daz-header__dropdown">
          <Dropdown.Toggle>
            <div className="daz-header__profilewrap text-center">
              <i className="small material-icons">face</i>
              <span className="txt-14">Profile</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <button
                className="daz-header__logout"
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('refresh_token');
                  localStorage.removeItem('token');
                  window.location.reload();
                }}>
                Logout
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}
