// const baseUrlConfig = {
//     baseUrl : 'http://dev.api.dazoq.com/reports/'
// }

const { NODE_ENV } = process.env;

const baseUrlConfig = {
  baseUrl:
    NODE_ENV == 'production' && process.env.REACT_APP_ENV != 'staging'
      ? 'https://api.dazoq.com/reports/'
      : 'https://dev.api.dazoq.com/reports/'
};

export default baseUrlConfig;
