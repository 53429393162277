const initialState = {
  company_process: {}
};

const companyProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_PROCESS':
      return {
        ...state,
        company_process: action.payload
      };
    default:
      return state;
  }
};

export default companyProcessReducer;
