import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { lazy } from 'react';
import { PrivateRoute } from './private.route';
import { PublicRoute } from './public.route';
const LoginContainer = lazy(() => import('../modules/login/containers/login.container'));
const ClientInformationContainer = lazy(() =>
  import('../modules/clientinformation/containers/clientinformation.container')
);
const ClientRegistration = lazy(() =>
  import('../modules/clientregistration/containers/clientregistration.container')
);

export const RedirectLogin = ({ children }) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);
  return children;
};

function AppRouter() {
  return (
    <Suspense fallback={'Loading....'}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                {' '}
                <LoginContainer />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                {' '}
                <LoginContainer />
              </PublicRoute>
            }
          />
          <Route
            path="/client-information"
            element={
              <PrivateRoute>
                <ClientInformationContainer />
              </PrivateRoute>
            }
          />
          <Route
            path="/client-registration/:companyId"
            element={
              <PrivateRoute>
                <ClientRegistration />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<RedirectLogin />} />
        </Routes>
      </Router>
      {/* <Navigate from="*" to="/login" /> */}
    </Suspense>
  );
}

export default AppRouter;
