import { combineReducers } from 'redux';
import companyProcessReducer from './companyprocesslist/companyprocesslist.reducer';
import pageHeadingReducer from './pageheading/pageheading.reducer';

const rootReducer = combineReducers({
  pageHeadingReducer: pageHeadingReducer,
  companyProcessReducer: companyProcessReducer
});

export default rootReducer;
