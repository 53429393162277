import { Navigate } from 'react-router-dom';
import LayoutComponents from '../hoc/layout/components/layout.components';

export const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return <LayoutComponents>{children}</LayoutComponents>;
};
