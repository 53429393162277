import React from 'react';
import HeaderContainer from '../../../common/header/container/header.container';
import { Provider } from 'react-redux';
import store from '../../../redux/store';

export default function LayoutComponents(props) {
  return (
    <Provider store={store}>
      <div className="daz-admin-wrp">
        <HeaderContainer />
        <div className="daz-main-wrp">
          <div className="daz-main-content">{props.children}</div>
        </div>
      </div>
    </Provider>
  );
}
